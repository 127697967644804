import { systemLogin, logout, getInfo } from "@/api/login";
import { getToken, setToken, removeToken, removeUserInfo } from "@/utils/auth";
import aes from "@/utils/aes";
import Cookies from "js-cookie";

const user = {
    state: {
        id: null,
        token: getToken(),
        name: "",
        avatar: "",
        roles: [],
        permissions: [],
        phoneNo: "", // 登录是否要重置密码,需要的话登录接口返回手机号
    },
    getters: {
        hasDashboardPermission(state) {
            let userInfo = localStorage.getItem("loginUserInfo");
            if (userInfo) {
                userInfo = JSON.parse(userInfo);
                if (
                    (userInfo.userType && userInfo.userType == "00") ||
                    userInfo.permissions.indexOf("system:dataDashboard:admin") > -1
                ) {
                    return true;
                }
            }
            return false;
        },
        localStorageUserInfo(state) {
            let userInfo = localStorage.getItem("loginUserInfo");
            if (userInfo) {
                userInfo = JSON.parse(userInfo);
                return userInfo;
            }
            return {};
        },
    },
    mutations: {
        SET_ID: (state, id) => {
            state.id = id;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_NAME: (state, name) => {
            state.name = name;
        },
        SET_CUSTOMERID: (state, id) => {
            state.customerId = id;
        },
        SET_CUSTOMERNAME: (state, name) => {
            state.customerName = name;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions;
        },
        SET_USERTYPE: (state, userType) => {
            state.userType = userType;
        },
        SET_PHONENO: (state, phoneNo) => {
            state.phoneNo = phoneNo;
        },
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const username = userInfo.username.trim();
            const password = userInfo.password;
            const code = userInfo.code;
            const uuid = userInfo.uuid;
            const unionid = userInfo.unionid;
            const openid = userInfo.openid;
            const aesKey = userInfo.aesKey;
            const originAesKey = userInfo.originAesKey;
            const ticket = userInfo.ticket;
            const randStr = userInfo.randStr;
            const param = {
                username,
                password,
                code,
                uuid,
                aesKey,
                ticket,
                randStr,
                unionid,
                openid,
            };
            return new Promise((resolve, reject) => {
                systemLogin(param)
                    .then((res) => {
                        if (res.code === 200) {
                            // 返回token直接登录
                            if (res.data.token) {
                                // token解码
                                let token = aes.aesDecrypt(
                                    res.data.token,
                                    originAesKey
                                );
                                setToken(token);
                                commit("SET_TOKEN", token);
                            }
                            // 返回手机号, 需要重置密码
                            if (res.data.code == 1) {
                                commit("SET_PHONENO", res.data.phoneNo);
                            }
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo(state.token)
                    .then((res) => {
                        const userInfo = res.data.user;
                        const customerName = res.data.customerName;
                        Cookies.set("customerName", customerName);
                        const customerId = userInfo.customerId;
                        if (userInfo.avatar != null) {
                            if (
                                userInfo.avatar.includes("http") ||
                                userInfo.avatar == ""
                            ) {
                                var avatar = "https://dobay-document-1304378824.file.myqcloud.com/dashboard_mangguo/avatar.png";
                            } else {
                                var avatar =
                                    "http://192.168.0.3:8090" +
                                    userInfo.avatar;
                            }
                        } else {
                            var avatar = "https://dobay-document-1304378824.file.myqcloud.com/dashboard_mangguo/avatar.png";
                        }

                        // 验证返回的roles是否是一个非空数组
                        if (res.data.roles && res.data.roles.length > 0) {
                            commit("SET_ROLES", res.data.roles);
                            commit("SET_PERMISSIONS", res.data.permissions);
                        } else {
                            commit("SET_ROLES", ["ROLE_DEFAULT"]);
                        }
                        commit("SET_ID", userInfo.userId);
                        commit("SET_NAME", userInfo.userName);
                        commit("SET_USERTYPE", userInfo.userType);
                        commit("SET_CUSTOMERID", customerId);
                        commit("SET_CUSTOMERNAME", customerName);
                        commit("SET_AVATAR", avatar);
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token)
                    .then(() => {
                        commit("SET_TOKEN", "");
                        commit("SET_ROLES", []);
                        commit("SET_PERMISSIONS", []);
                        removeToken();
                        removeUserInfo();
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // 前端 登出
        FedLogOut({ commit }) {
            return new Promise((resolve) => {
                commit("SET_TOKEN", "");
                removeToken();
                removeUserInfo();
                resolve();
            });
        },
    },
};

export default user;
